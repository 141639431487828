import {
  getAccountPathFromToken,
  getSportsBookFromToken,
} from '../auth/authActions';
import { Currency } from '../../models/Currency';

import {
  IS_ADMIN_REPORTS_URL,
  IS_ADMIN_RISKMGMT_URL,
  IS_HOUSE_URL,
  TOGGLE_DARK_MODE,
  FETCH_BETTING_CURRENCY_SUCCESS,
  FETCH_BETTING_CURRENCY_FAILED,
  CASINO_GAME_SELECTED,
  SET_DC_GAME_URL,
  SET_LIVESTREAM_URL,
  SET_PLAY_STREAM,
  SET_ALLOWED_CONFIG,
  ENABLE_COMMISSION,
  TRIGGER_FETCH_BALANCE,
  TRIGGER_FETCH_NOTIFICATIONS,
  SET_DOMAIN_CONFIG,
  FETCH_CONTENT_CONFIG_SUCCESS,
  SET_CONTACT_LIST
} from './commonActionTypes';

import { CurrencyDetails } from '../../models/CurrencyDetails';
import moment from 'moment';
import { DomainConfig } from '../../models/DomainConfig';
import SVLS_API from '../../svls-api';
import { BRAND_DOMAIN } from '../../constants/Branding';
import { isMobile } from 'react-device-detect';

export const activateReportsTab = (value: boolean) => {
  return {
    type: IS_ADMIN_REPORTS_URL,
    payload: value,
  };
};

export const activateRiskMgmtTab = (value: boolean) => {
  return {
    type: IS_ADMIN_RISKMGMT_URL,
    payload: value,
  };
};

export const activateHouseTab = (value: boolean) => {
  return {
    type: IS_HOUSE_URL,
    payload: value,
  };
};

export const toggleDarkMode = (value: string) => {
  return {
    type: TOGGLE_DARK_MODE,
    payload: value,
  };
};

export const setPlayStream = (value: boolean) => {
  return {
    type: SET_PLAY_STREAM,
    payload: value,
  };
};

const fetchBettingCurrencySuccess = (currency: CurrencyDetails) => {
  return {
    type: FETCH_BETTING_CURRENCY_SUCCESS,
    payload: currency,
  };
};

const fetchBettingCurrencyFailed = () => {
  return {
    type: FETCH_BETTING_CURRENCY_FAILED,
  };
};

export const fetchBettingCurrency = () => {
  return async (dispatch: Function) => {
    /*
    try {
      const response = await API.get('/user/betting-currency', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      dispatch(fetchBettingCurrencySuccess(response.data));
    } catch (err) {
      dispatch(fetchBettingCurrencyFailed());
      if (err.response && err.response.status === 401) {
        const token = sessionStorage.getItem('jwt_token');
        if (token) {
          dispatch(logout());
        }
      }
    }
  */
  };
  return Currency.Pts;
};
export const setContactList = (contactList) => {
  return {
    type: SET_CONTACT_LIST,
    payload: contactList,
  };
};

export const fetchContactData = () => {
  return async (dispatch: Function) => {
    try {
      const response = await SVLS_API.get(
        `/account/v2/books/${BRAND_DOMAIN}/contact-list`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        dispatch(setContactList(response.data));
      }
    } catch (err) {
      console.error('Error fetching contact details:', err);
    }
  };
};

export const casinoGameSelected = (casinoGame: {
  id: string;
  name: string;
}) => {
  return {
    type: CASINO_GAME_SELECTED,
    payload: casinoGame,
  };
};

export const setDcGameUrl = (url: string) => {
  return {
    type: SET_DC_GAME_URL,
    payload: url,
  };
};

export const setLivestreamUrl = (url: string) => {
  return {
    type: SET_LIVESTREAM_URL,
    payload: url,
  };
};

export const setAllowedConfig = (allowedConfig: number) => {
  return {
    type: SET_ALLOWED_CONFIG,
    payload: allowedConfig,
  };
};

export const setDomainConfig = (config: DomainConfig) => {
  return {
    type: SET_DOMAIN_CONFIG,
    payload: config,
  };
};

export const enableCommission = (commission: boolean) => {
  return {
    type: ENABLE_COMMISSION,
    payload: commission,
  };
};

export const triggerFetchBalance = (eventTime: number) => {
  return {
    type: TRIGGER_FETCH_BALANCE,
    payload: eventTime,
  };
};

export const triggerFetchNotifications = (data) => {
  var adminAccountPath = data.adminAccountPath.concat('/');
  if (
    getAccountPathFromToken().includes(adminAccountPath) &&
    (data.sportsBooks.includes('all') ||
      data.sportsBooks.includes(getSportsBookFromToken()))
  ) {
    return {
      type: TRIGGER_FETCH_NOTIFICATIONS,
      payload: moment.now(),
    };
  }
};

export const isAccountPath = (limitKey: string) => {
  return !limitKey.includes('/CT/SPORTS');
};

export const fetchContentConfig = () => {
  return async (dispatch: Function) => {
    try {
      const response = await SVLS_API.get(
        `/account/v2/books/cache/${BRAND_DOMAIN}/content-config`
      );
      if (!response?.data?.default_template) {
        dispatch(fetchContentConfigSuccess(response?.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

const fetchContentConfigSuccess = (result) => {
  return {
    type: FETCH_CONTENT_CONFIG_SUCCESS,
    payload: result,
  };
};

export const redirectGame = async (
  gameId: any,
  gameCode: any,
  provider: any,
  subProvider: any
) => {
  let response;
  try {
    const reqBody = {
      gameId: gameId,
      providerName: provider,
      subProviderName: subProvider,
      platformId: isMobile ? 'mobile' : 'desktop',
    };
    response = await SVLS_API.post(
      '/catalog/v2/live-casino/game-url',
      reqBody,
      {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      }
    );
    if (response) {
      window.open(response.data, '_blank');
    }
  } catch (e) {
    console.log(e);
  }
};
